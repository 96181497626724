import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import StepAnimation from './stepAnimation/StepAnimation';
import FormButtons from './FormButtons';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchTestById, fetchBrowserTestById } from '../../store/slice/test/TestActions';
import { fetchAllEnvironmentsInTestSuite } from '../../store/slice/environment/EnvironmentActions';
import { commonActions } from '../../store/slice/CommonSlice';
import {
  createTest,
  createBrowserTest,
  updateTest,
  updateBrowserTest,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  fetchAllTestsInTestSuite,
  fetchAllBrowserTestsInTestSuite
} from '../../store/slice/test/TestActions';
import { fetchSuiteById } from '../../store/slice/suite/SuiteActions';
import TestDetailsForm from './createTestForms/TestDetailsForm';
import TestEnvironmentsForm from './createTestForms/TestEnvironmentsForm';
import TestFrequencyForm from './createTestForms/TestFrequencyForm';
import StepDetailsForm from './createTestForms/stepDetailsForm/StepDetailsForm';
import TestConditionsForm from './createTestForms/TestConditionsForm';
import AlertConditionsForm from './createTestForms/AlertConditionsForm';
import { steps } from './stepAnimation/steps';
import AppBreadCrumb from '../../components/breadCrumbs/AppBreadCrumb';
import { HomeBreadCrumb, TestSuiteBreadCrumb } from '../../utils/BreadCrumbUtils';
import _ from 'lodash';
import { TEST_TYPES } from '../../utils/TestTypes';
import { WEBHOOK_REGEX } from '../../utils/WebhookRegex';

const CreateTest = () => {
  const navigate = useNavigate();
  const { suiteId, testId } = useParams();
  const dispatch = useDispatch();
  const path = window.location.pathname;
  const type = `${path.indexOf(TEST_TYPES.BROWSER)}` === '-1' ? TEST_TYPES.API : TEST_TYPES.BROWSER;
  const TestSuiteDetailsBreadCrumb = {
    link: `${path.substring(0, path.lastIndexOf('/'))}`,
    title: 'Test Suite Details'
  };
  const breadcrumbAr = [HomeBreadCrumb, TestSuiteBreadCrumb, TestSuiteDetailsBreadCrumb];
  let testFromDatabase = testId
    ? type === TEST_TYPES.BROWSER
      ? useSelector((state) => state.testData.browserTest)
      : useSelector((state) => state.testData.test)
    : undefined;
  let environmentsFromDatabase = useSelector((state) => state.environmentData.environments);
  let schedulesFromDataBase = testFromDatabase?.schedules;

  const [step, setStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const [name, setName] = useState(testFromDatabase?.name || '');
  const [description, setDescription] = useState(testFromDatabase?.description || '');
  const [browserDeviceMappings, setBrowserDeviceMappings] = useState(
    testFromDatabase?.browserDeviceMappings || []
  );
  const [browserDeviceMappingChangeFlag, setBrowserDeviceMappingChangeFlag] = useState(false);
  const [startingUrl, setStartingUrl] = useState(testFromDatabase?.startingUrl || '');
  const [webhookError, setWebhookError] = useState('');
  const [script, setScript] = useState(testFromDatabase?.script || '');
  const [testSchedules, setTestSchedules] = useState(testFromDatabase?.schedules || []);
  const [stepsList, setStepsList] = useState(testFromDatabase?.steps || []);
  const [scriptValidateFlag, setScriptValidateFlag] = useState(false);
  const [continueOnFailure, setContinueOnFailure] = useState(
    testFromDatabase?.continueOnFailure || false
  );
  const [sslDisabled, setSslDisabled] = useState(testFromDatabase?.sslDisabled || false);
  const [retryCount, setRetryCount] = useState(testFromDatabase?.retryCount || 0);
  const [alertCondition, setAlertCondition] = useState(testFromDatabase?.alertCondition || {});
  const [alertWebhook, setAlertWebhook] = useState(testFromDatabase?.alertWebhook || '');
  const [hideFormButtons, setHideFormButtons] = useState(false);
  const suite = useSelector((state) => state.suiteData.suite);
  const [customLabels, setCustomLabels] = useState(alertCondition?.customLabels || {});

  useEffect(() => {
    if (testId) {
      type === TEST_TYPES.API
        ? dispatch(fetchTestById(suiteId, testId))
        : dispatch(fetchBrowserTestById(suiteId, testId));
    }
    dispatch(fetchSuiteById(suiteId));
    dispatch(fetchAllEnvironmentsInTestSuite(suiteId));
  }, []);

  const handleTestEnvironments = (checked, value) => {
    if (value === 'all') {
      if (checked === false) {
        setTestSchedules([]);
      } else {
        setTestSchedules((schedules) =>
          environmentsFromDatabase
            .map(
              (dbEnv) =>
                schedules?.find((localSched) => localSched.environmentId === dbEnv.id) ||
                schedulesFromDataBase?.find((dbSched) => dbSched.environmentId === dbEnv.id) || {
                  environmentId: dbEnv.id,
                  environmentName: dbEnv.name
                }
            )
            .sort((a, b) => a.environmentId - b.environmentId)
        );
      }
    } else {
      if (checked) {
        setTestSchedules((schedules) => {
          const dbEnv = environmentsFromDatabase.find((e) => e.name === value);
          const dbSched = schedulesFromDataBase?.find(
            (dbSched) => dbSched?.environmentName === dbEnv.name
          );
          return [
            ...schedules,
            dbSched || {
              environmentId: dbEnv.id,
              environmentName: dbEnv.name
            }
          ].sort((a, b) => a.environmentId - b.environmentId);
        });
      } else {
        //delete schedules for an env when it is unchecked
        setTestSchedules((prevSched) =>
          prevSched.filter((schedule) => schedule.environmentName !== value)
        );
      }
    }
  };

  const handleTestSchedules = (event) => {
    if (event.name) {
      const splitAt = event.name.lastIndexOf('.');
      const envName = event.name.substring(0, splitAt);
      const fieldName = event.name.substring(splitAt + 1);

      //update the schedule's field value
      setTestSchedules((schedules) => {
        const indexOfUpdate = schedules.findIndex((sched) => sched.environmentName === envName);
        const updatedSched = { ...schedules[indexOfUpdate], [fieldName]: event.value };
        const updatedSchedWithType = {
          ...updatedSched,
          testType: type === TEST_TYPES.API ? 'api' : 'browser'
        };
        //merge in the updated schedule
        return [
          ...schedules.slice(0, indexOfUpdate),
          updatedSchedWithType,
          ...schedules.slice(indexOfUpdate + 1)
        ];
      });
    }
  };

  const handleStepsList = (OBJ) => {
    setStepsList((stepsList) => [...stepsList, OBJ]);
  };

  const handleUpdatedStepsList = (updatedStep) => {
    let temp = [...stepsList];
    let index = stepsList.findIndex((step) =>
      updatedStep.id ? step.id === updatedStep.id : step.localID === updatedStep.localID
    );
    temp[index] = updatedStep;
    setStepsList(temp);
  };

  const handleTestConditions = (element, target) => {
    if (element === 'retryCount') {
      setRetryCount(target.value);
    } else if (element === 'continueOnFailure') {
      setContinueOnFailure(target.checked);
    } else if (element === 'sslDisabled') {
      setSslDisabled(target.checked);
    }
  };

  const handleAlertCondition = (value, key) => {
    if (key === 'type' && value === '') {
      setCustomLabels({});
      setAlertCondition({ type: '', threshold: '', customLabels: {} });
      setAlertWebhook('');
    } else {
      setAlertCondition((alertCondition) => {
        return {
          ...alertCondition,
          type: key === 'type' ? value : alertCondition.type,
          threshold: key === 'threshold' ? value : alertCondition.threshold
        };
      });
      if (key === 'webhook') {
        setWebhookError(!value.length || WEBHOOK_REGEX.test(value) ? '' : 'Incorrect webhook url');
        setAlertWebhook(value);
      }
    }
  };

  const handleTestDetailsUpdate = (step) => {
    if (
      testFromDatabase.name !== name ||
      testFromDatabase.description !== description ||
      testFromDatabase.alertWebhook !== alertWebhook ||
      !(
        _.isEqual(testFromDatabase.alertCondition, alertCondition) ||
        testFromDatabase.alertCondition == null ||
        Object.keys(alertCondition).length == 0
      ) ||
      (type === TEST_TYPES.API
        ? testFromDatabase.retryCount !== retryCount ||
          testFromDatabase.continueOnFailure !== continueOnFailure ||
          testFromDatabase.sslDisabled !== sslDisabled
        : testFromDatabase.startingUrl !== startingUrl ||
          testFromDatabase.script !== script ||
          browserDeviceMappingChangeFlag)
    ) {
      let newTestDef =
        type === TEST_TYPES.API
          ? {
              id: testId,
              testSuiteId: suiteId,
              name: name,
              description: description,
              alertWebhook: alertWebhook,
              alertCondition: alertWebhook ? alertCondition : null,
              continueOnFailure: continueOnFailure,
              sslDisabled: sslDisabled,
              retryCount: retryCount,
              steps: stepsList
            }
          : {
              id: testId,
              testSuiteId: suiteId,
              name: name,
              description: description,
              startingUrl: startingUrl,
              browserDeviceMappings: browserDeviceMappings,
              alertWebhook: alertWebhook,
              alertCondition: alertWebhook ? alertCondition : null,
              retryCount: retryCount,
              script: script
            };
      const updateAction =
        type === TEST_TYPES.BROWSER
          ? updateBrowserTest(suiteId, testId, newTestDef)
          : updateTest(suiteId, testId, newTestDef);
      dispatch(updateAction).then(
        () => {
          dispatch(commonActions.showSuccessAlert(`Test ${newTestDef.name} updated`));
          type === TEST_TYPES.API
            ? dispatch(fetchTestById(suiteId, testId))
            : dispatch(fetchBrowserTestById(suiteId, testId));
        },
        function (error) {
          dispatch(commonActions.showErrorAlert(error.response.data.message));
        }
      );
    }
    if (step === -1) {
      navigate(`/suites/${suiteId}`);
    }
  };

  const handleEnvironmentsUpdateInTest = () => {
    let selectedEnvs = testSchedules.map((schedule) => schedule.environmentName);
    let selectedEnvsSorted = selectedEnvs.sort(function (a, b) {
      return a.localeCompare(b);
    });
    let schedulesFromDB = [...schedulesFromDataBase];
    let schedulesFromDBSorted = schedulesFromDB.sort(function (a, b) {
      return a.environmentName.localeCompare(b.environmentName);
    });
    let noDifference = false;
    noDifference =
      selectedEnvsSorted.length === schedulesFromDBSorted.length &&
      selectedEnvsSorted.every(
        (selectedEnv, i) => selectedEnv === schedulesFromDBSorted[i].environmentName
      );
    if (!noDifference) {
      let deletedSchedules = schedulesFromDBSorted.filter(
        (apiSchedule) => !selectedEnvsSorted.find((env) => apiSchedule.environmentName === env)
      );

      if (deletedSchedules && deletedSchedules.length > 0) {
        deletedSchedules.map((apiSchedule) => {
          dispatch(deleteSchedule(suiteId, testId, apiSchedule.id)).then(
            () => {
              dispatch(
                commonActions.showSuccessAlert(
                  `Schedule cleared for ${apiSchedule.environmentName}`
                )
              );
              type === TEST_TYPES.API
                ? dispatch(fetchTestById(suiteId, testId))
                : dispatch(fetchBrowserTestById(suiteId, testId));
            },
            function () {
              dispatch(
                commonActions.showErrorAlert(
                  `Failed to clear schedule for ${apiSchedule.environmentName}`
                )
              );
            }
          );
        });
      }
    }
  };

  const handleFrequencyUpdateInTest = () => {
    let testSchedulesFromDB = [...schedulesFromDataBase];
    let testSchedulesLocal = [...testSchedules];
    const sortedTestSchedules = testSchedulesLocal.sort(
      (a, b) => a.environmentId - b.environmentId
    );
    const sortedTestSchedulesFromDB = testSchedulesFromDB.sort(
      (a, b) => a.environmentId - b.environmentId
    );
    let noDiff = false;
    noDiff =
      sortedTestSchedules.length === sortedTestSchedulesFromDB.length &&
      sortedTestSchedulesFromDB.every(
        (sortedScheduleFromDB, i) =>
          sortedScheduleFromDB.environmentId === sortedTestSchedules[i].environmentId &&
          sortedScheduleFromDB.duration === sortedTestSchedules[i].duration
      );
    if (!noDiff) {
      let newSchedules = sortedTestSchedules.filter(
        (newSchedule) =>
          !sortedTestSchedulesFromDB.find(
            (sortedTestScheduleFromDB) =>
              newSchedule.environmentId === sortedTestScheduleFromDB.environmentId
          )
      );

      let updatedSchedules = sortedTestSchedulesFromDB.filter((apiSchedule) =>
        sortedTestSchedules.find(
          (sortedTestSchedule) =>
            apiSchedule.environmentId === sortedTestSchedule.environmentId &&
            ((apiSchedule.cron.toString() === 'false' &&
              apiSchedule.cron.toString() === sortedTestSchedule.cron.toString() &&
              apiSchedule.duration !== sortedTestSchedule.duration) ||
              (apiSchedule.cron.toString() === 'true' &&
                apiSchedule.cron.toString() === sortedTestSchedule.cron.toString() &&
                apiSchedule.cronExpression !== sortedTestSchedule.cronExpression) ||
              apiSchedule.cron.toString() !== sortedTestSchedule.cron.toString())
        )
      );

      if (newSchedules && newSchedules.length > 0) {
        let newSchedulesWithoutUpdated = newSchedules.filter(
          (newSchedule) =>
            !updatedSchedules.find(
              (updatedSchedule) => newSchedule.environmentId === updatedSchedule.environmentId
            )
        );
        newSchedulesWithoutUpdated.map((newSchedule) => {
          dispatch(createSchedule(suiteId, testId, newSchedule)).then(
            () => {
              dispatch(
                commonActions.showSuccessAlert(
                  `Schedule created for ${newSchedule.environmentName}`
                )
              );
              type === TEST_TYPES.API
                ? dispatch(fetchTestById(suiteId, testId))
                : dispatch(fetchBrowserTestById(suiteId, testId));
            },
            function () {
              dispatch(
                commonActions.showErrorAlert(
                  `Failed to create schedule for ${newSchedule.environmentName}`
                )
              );
            }
          );
        });
      }
      if (updatedSchedules && updatedSchedules.length > 0) {
        let updatedSchedulesNew = updatedSchedules.map((updatedSchedule) => {
          let a = testSchedules.find(
            (testSchedule) => testSchedule.environmentId === updatedSchedule.environmentId
          );
          return {
            ...updatedSchedule,
            duration: a.duration,
            cron: a.cron,
            cronExpression: a.cronExpression,
            testType: type === TEST_TYPES.API ? 'api' : 'browser'
          };
        });
        updatedSchedulesNew.map((updatedSchedule) => {
          dispatch(updateSchedule(suiteId, testId, updatedSchedule.id, updatedSchedule)).then(
            () => {
              dispatch(
                commonActions.showSuccessAlert(
                  `Schedule updated for ${updatedSchedule.environmentName}`
                )
              );
              type === TEST_TYPES.API
                ? dispatch(fetchTestById(suiteId, testId))
                : dispatch(fetchBrowserTestById(suiteId, testId));
            },
            function () {
              dispatch(
                commonActions.showErrorAlert(
                  `Failed to update schedule for ${updatedSchedule.environmentName}`
                )
              );
            }
          );
        });
      }
    }
  };

  const handleUpdate = (step) => {
    if (
      step === -1 ||
      (steps[step - 1] &&
        (steps[step - 1].label === 'Test Details' ||
          steps[step - 1].label === 'Test Conditions' ||
          (type === TEST_TYPES.BROWSER && steps[step - 1].label === 'Step Details')))
    ) {
      handleTestDetailsUpdate(step);
    } else if (steps[step - 1] && steps[step - 1].label === 'Environments') {
      handleEnvironmentsUpdateInTest();
    } else if (steps[step - 1] && steps[step - 1].label === 'Test Frequency') {
      handleFrequencyUpdateInTest();
    }
  };

  const handleTestOBJ = (type) => {
    let localTestData = {
      testSuiteId: suiteId,
      name: name,
      description: description,
      alertWebhook: alertWebhook,
      alertCondition: alertWebhook ? alertCondition : null,
      continueOnFailure: continueOnFailure,
      sslDisabled: sslDisabled,
      retryCount: retryCount
    };
    let localCompositeData = {};
    if (type !== TEST_TYPES.BROWSER) {
      localCompositeData = {
        ...localTestData,
        steps: stepsList,
        schedules: testSchedules
      };
      if (!testId) {
        dispatch(commonActions.showLoadingIcon(true));
        dispatch(createTest(suiteId, localCompositeData)).then(
          () => {
            dispatch(commonActions.showSuccessAlert(`Test ${localCompositeData.name} created`));
            dispatch(commonActions.showLoadingIcon(false));
            dispatch(fetchAllTestsInTestSuite(suiteId));
            dispatch(fetchAllBrowserTestsInTestSuite(suiteId));
            navigate(`/suites/${suiteId}`);
          },
          function (error) {
            dispatch(commonActions.showLoadingIcon(false));
            dispatch(commonActions.showErrorAlert(error.response.data.message));
          }
        );
      }
    } else {
      localCompositeData = {
        ...localTestData,
        startingUrl: startingUrl,
        browserDeviceMappings: browserDeviceMappings,
        script: script,
        schedules: testSchedules
      };
      if (!testId) {
        dispatch(commonActions.showLoadingIcon(true));
        dispatch(createBrowserTest(suiteId, localCompositeData)).then(
          () => {
            dispatch(commonActions.showSuccessAlert(`Test ${localCompositeData.name} created`));
            dispatch(commonActions.showLoadingIcon(false));
            dispatch(fetchAllTestsInTestSuite(suiteId));
            dispatch(fetchAllBrowserTestsInTestSuite(suiteId));
            navigate(`/suites/${suiteId}`);
          },
          function (error) {
            dispatch(commonActions.showLoadingIcon(false));
            dispatch(commonActions.showErrorAlert(error.response.data.message));
          }
        );
      }
    }
  };

  const toggleHideFormButtons = () => {
    setHideFormButtons(!hideFormButtons);
  };

  const handleCancel = () => {
    if (testId) {
      setTestSchedules([]);
      testFromDatabase = [];
      type === TEST_TYPES.BROWSER
        ? navigate(`/suites/${suiteId}/${TEST_TYPES.BROWSER}/${testId}`)
        : navigate(`/suites/${suiteId}/${TEST_TYPES.API}/${testId}`);
    } else {
      navigate(`/suites/${suiteId}`);
    }
  };

  return (
    <>
      <AppBreadCrumb data={breadcrumbAr} />
      <Container maxWidth="lg" sx={{ py: '1.5rem' }}>
        <Grid container sx={{ py: '1rem' }}>
          <Grid item xs={2.5}>
            {testId ? <h4>Update Test</h4> : <h4>Create New Test</h4>}
            {testId && name}
          </Grid>
          <Grid item xs={9.5} sx={{ px: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <p style={{ fontSize: 12, fontWeight: 700, marginBottom: '.5rem' }}>Test Suite</p>
                <p>{suite.name}</p>
              </Box>
              <Box>
                <br />
                {!testId && <p>Follow the steps below to create a test</p>}
                {testId && <p>Follow the steps below to update a test</p>}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* CRUFT: we shouldn't need to set a rigid height */}
        <Grid container sx={{ minHeight: '60vh' }}>
          <Grid item xs={2.5} sx={{ py: '2rem' }}>
            <StepAnimation steps={steps} activeStep={activeStep} />
          </Grid>
          <Grid
            item
            xs={9.5}
            bgcolor="bgSecondary"
            sx={{
              minHeight: 450,
              p: 4
            }}>
            <Paper rounded="true" elevation={5} sx={{ p: 2, height: '100%' }}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                <Box>
                  <p
                    style={{
                      color: '#888888',
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: '.5rem'
                    }}>
                    step {step + 1}/{steps.length}
                  </p>
                  <h3>
                    {type === TEST_TYPES.BROWSER && step === 3
                      ? 'Script Details'
                      : steps[step].label}
                  </h3>
                  <p>
                    {step === 5 &&
                      'Note: Test Level alert conditions take precedence over Environment Level alert conditions.'}
                  </p>
                  <TestDetailsForm
                    step={step}
                    name={name}
                    setName={setName}
                    description={description}
                    setDescription={setDescription}
                    startingUrl={startingUrl}
                    setStartingUrl={setStartingUrl}
                    browserDeviceMappings={browserDeviceMappings}
                    setBrowserDeviceMappings={setBrowserDeviceMappings}
                    setBrowserDeviceMappingChangeFlag={setBrowserDeviceMappingChangeFlag}
                    type={type}
                  />
                  <TestEnvironmentsForm
                    step={step}
                    handleTestEnvironments={handleTestEnvironments}
                    environments={environmentsFromDatabase.slice().sort((a, b) => a.id - b.id)}
                    selectedEnvironments={
                      testSchedules?.map((sched) => sched?.environmentName) || []
                    }
                  />
                  <TestFrequencyForm
                    step={step}
                    handleTestSchedules={handleTestSchedules}
                    schedules={testSchedules}
                    type={type}
                  />
                  <StepDetailsForm
                    step={step}
                    toggleHideFormButtons={toggleHideFormButtons}
                    stepsList={stepsList}
                    setStepsList={setStepsList}
                    handleStepsList={handleStepsList}
                    handleUpdatedStepsList={handleUpdatedStepsList}
                    type={type}
                    script={script}
                    setScript={setScript}
                    setScriptValidateFlag={setScriptValidateFlag}
                  />
                  <TestConditionsForm
                    step={step}
                    continueOnFailure={continueOnFailure}
                    retryCount={retryCount}
                    sslDisabled={sslDisabled}
                    handleTestConditions={handleTestConditions}
                    type={type}
                  />
                  <AlertConditionsForm
                    step={step}
                    handleAlertCondition={handleAlertCondition}
                    webhookError={webhookError}
                    alertCondition={alertCondition}
                    setAlertCondition={setAlertCondition}
                    alertWebhook={alertWebhook}
                    customLabels={customLabels}
                    setCustomLabels={setCustomLabels}
                    environment={false}
                  />
                </Box>
                <Box hidden={hideFormButtons}>
                  <FormButtons
                    name={name}
                    description={description}
                    startingUrl={startingUrl}
                    browserDeviceMappings={browserDeviceMappings}
                    schedules={testSchedules}
                    handleCancel={handleCancel}
                    handleTestOBJ={handleTestOBJ}
                    handleUpdate={(step) => handleUpdate(step)}
                    setActiveStep={setActiveStep}
                    setStep={setStep}
                    step={step}
                    steps={steps}
                    script={script}
                    alertCondition={alertCondition}
                    alertWebhook={alertWebhook}
                    webhookError={webhookError}
                    type={type}
                    scriptValidateFlag={scriptValidateFlag}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default CreateTest;
