import { v4 as uuid } from 'uuid';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import StepDetailsTabs from './stepDetailsTabs/StepDetailsTabs';
import StepList from './StepList';
import axiosInstance from '../../../../services/axios';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  createAssertion,
  deleteAssertion,
  deleteStep,
  rearrangeStep,
  updateAssertion
} from '../../../../store/slice/test/TestActions';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../../../store/slice/CommonSlice';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import * as Babel from '@babel/parser';
import { TEST_TYPES } from '../../../../utils/TestTypes';
import ConfirmDialog from '../../../../components/modal/ConfirmDialog';

const StepDetailsForm = ({
  step,
  toggleHideFormButtons,
  stepsList,
  setStepsList,
  handleStepsList,
  handleUpdatedStepsList,
  type,
  script,
  setScript,
  setScriptValidateFlag
}) => {
  const dispatch = useDispatch();
  const { suiteId, testId } = useParams();
  const [showStepforms, setShowStepforms] = useState(false);
  const [openAssertionsModal, setOpenAssertionsModal] = useState(false);
  const [executionOrder, setExecutionOrder] = useState(1);
  const [stepName, setStepName] = useState('');
  const [stepDescription, setStepDescription] = useState('');
  const [stepMethod, setStepMethod] = useState('GET');
  const [stepURL, setStepURL] = useState('');
  const [operandsList, setOperandsList] = useState([]);
  const [assertionsFromDb, setAssertionsFromDb] = useState([]);
  const [assertionType, setAssertionType] = useState('');
  const [assertionField, setAssertionField] = useState('');
  const [assertionOperand, setAssertionOperand] = useState('');
  const [assertionValue, setAssertionValue] = useState('');
  const [assertionsList, setAssertionsList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [headers, setHeaders] = useState({});
  const [contentType, setContentType] = useState('raw');
  const [body, setBody] = useState('');
  const [extractVariablesList, setExtractVariablesList] = useState([]);
  const [pauseDuration, setPauseDuration] = useState('');
  const [stepId, setStepId] = useState(0);
  const [localId, setLocalId] = useState(0);
  const [scripValidatorResponseMessage, setScripValidatorResponseMessage] = useState('');
  const [errorsList, setErrorsList] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [override, setOverride] = useState(false);
  const [secretsDetected, setSecretsDetected] = useState(false);

  useEffect(() => {
    if (type !== TEST_TYPES.BROWSER) {
      const getOperands = async () => {
        const fetchData = async () => {
          const response = await axiosInstance({
            method: 'get',
            url: '/v1/ui/operators'
          });
          const data = response.data;
          return data;
        };
        try {
          const operands = await fetchData();
          setOperandsList(operands);
        } catch (error) {
          console.log('error', error);
        }
      };
      getOperands();
    }
  }, []);

  useEffect(async () => {
    if (testId !== undefined) {
      if (stepId !== undefined && stepId !== 0) {
        await axiosInstance({
          method: 'get',
          url: `/v1/test_suites/${suiteId}/tests/${testId}/steps/${stepId}?expand=true`
        })
          .then((response) => {
            handleUpdatedStepsList(response.data);
            setAssertionsFromDb(response.data.assertions);
            setAssertionsList(response.data.assertions);
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    }
  }, [stepId, showStepforms]);

  const resetForm = () => {
    setStepId(0);
    setStepName('');
    setStepDescription('');
    setStepMethod('GET');
    setStepURL('');
    setAssertionsList([]);
    setHeaders({});
    setBody('');
    setContentType('raw');
    setExtractVariablesList([]);
    setPauseDuration('');
  };

  const handleResetAndShowStepForms = () => {
    resetForm();
    handleShowStepForms();
  };

  const handleShowStepForms = () => {
    toggleHideFormButtons();
    setShowStepforms(true);
  };

  const handleClose = () => {
    toggleHideFormButtons();
    setShowStepforms(false);
    resetForm();
    setEdit(false);
    setSecretsDetected(false);
  };

  const handleAddAssertion = (e, id, editStep) => {
    const reset = () => {
      setOpenAssertionsModal(false);
      setAssertionType('');
      setAssertionField('');
      setAssertionOperand('');
      setAssertionValue('');
    };

    if (edit) {
      const updateAssertionsList = () => {
        const newList = assertionsList.map((assertion) => {
          if (assertion.id === id || assertion.localID === id) {
            return {
              ...assertion,
              assertionType: assertionType,
              actualValue: assertionField,
              operator: assertionOperand,
              expectedValue: assertionValue
            };
          }
          return assertion;
        });
        setAssertionsList(newList);
      };
      updateAssertionsList();
      reset();
    } else {
      setAssertionsList((assertionsList) => [
        ...assertionsList,
        {
          assertionType: assertionType,
          actualValue: assertionField,
          operator: assertionOperand,
          expectedValue: assertionValue,
          localID: uuid(),
          id: stepId || undefined
        }
      ]);
      reset();
    }
    testId && editStep && setEdit(true);
    testId && !editStep && setEdit(false);
    !testId && editStep && setEdit(true);
    !testId && !editStep && setEdit(false);
  };

  const handleCreateStepInUpdateFlow = async (e) => {
    var secretsFound = false;
    e.preventDefault();
    setEdit(false);
    const newStep = {
      name: stepName,
      testId: testId,
      description: stepDescription,
      url: stepURL,
      body: body,
      headers: headers,
      method: stepMethod,
      assertions: assertionsList,
      pauseDuration: pauseDuration,
      extractVariables: extractVariablesList,
      localID: uuid(),
      id: stepId || undefined,
      skipSecretDetection: override
    };
    await axiosInstance({
      method: 'post',
      url: `/v1/test_suites/${suiteId}/tests/${testId}/steps/composite`,
      data: newStep
    })
      .then((response) => {
        setSecretsDetected(false);
        setOverride(false);
        handleStepsList(response.data);
        dispatch(commonActions.showSuccessAlert(`Step ${stepName} created successfully!`));
      })
      .catch((error) => {
        secretsFound =
          error.response.status === 400 &&
          error.response.data.message.startsWith('Potential secret');
        setSecretsDetected(secretsFound);
        dispatch(commonActions.showErrorAlert(error.response.data.message));
        console.log('error', error);
      });
    if (!secretsFound) {
      handleClose();
    }
  };

  const handleCreateStepOBJ = (e) => {
    e.preventDefault();
    const stepData = {
      name: stepName,
      description: stepDescription,
      url: stepURL,
      body: body,
      headers: headers,
      method: stepMethod,
      assertions: assertionsList,
      pauseDuration: pauseDuration,
      extractVariables: extractVariablesList,
      localID: stepId ? undefined : edit ? localId : uuid(),
      id: stepId || undefined,
      skipSecretDetection: override
    };

    edit ? handleUpdatedStepsList(stepData) : handleStepsList(stepData);
    setEdit(false);
    if (!secretsDetected) {
      handleClose();
    }
  };

  const handleUpdateStepInUpdateFlow = async (e) => {
    var secretsFound = false;
    e.preventDefault();
    const stepData = {
      name: stepName,
      testId: testId,
      description: stepDescription,
      url: stepURL,
      executionOrder: executionOrder,
      body: body,
      headers: headers,
      method: stepMethod,
      assertions: assertionsList,
      pauseDuration: pauseDuration,
      extractVariables: extractVariablesList,
      localID: uuid(),
      id: stepId,
      skipSecretDetection: override
    };
    dispatch(commonActions.showLoadingIcon(true));
    await axiosInstance({
      method: 'put',
      url: `/v1/test_suites/${suiteId}/tests/${testId}/steps/${stepId}`,
      data: stepData
    })
      .then(() => {
        setSecretsDetected(false);
        setOverride(false);
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(commonActions.showSuccessAlert(`Step ${stepName} updated successfully!`));
      })
      .catch((error) => {
        secretsFound =
          error.response.status === 400 &&
          error.response.data.message.startsWith('Potential secret');
        setSecretsDetected(secretsFound);
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      });
    if (assertionsList.length > 0) {
      let assertionsToBeDeleted = assertionsFromDb.filter(
        (assertionFromDb) =>
          !assertionsList.some((assertion) => assertion.id === assertionFromDb.id)
      );
      if (assertionsToBeDeleted.length > 0) {
        assertionsToBeDeleted.map((assertion) => {
          dispatch(deleteAssertion(suiteId, testId, stepId, assertion.id));
        });
      }

      let assertionsToBeAdded = assertionsList.filter(
        (assertion) =>
          !assertionsFromDb.some((assertionFromDb) => assertionFromDb.id === assertion.id)
      );
      if (assertionsToBeAdded.length > 0) {
        assertionsToBeAdded.map((assertion) => {
          assertion.stepId = stepId;
          dispatch(createAssertion(suiteId, testId, stepId, assertion));
        });
      }

      let updatedAssertions = assertionsFromDb.filter((assertionFromDb) =>
        assertionsList.find(
          (assertion) =>
            assertionFromDb.id === assertion.id &&
            (assertionFromDb.assertionType !== assertion.assertionType ||
              assertionFromDb.actualValue !== assertion.actualValue ||
              assertionFromDb.operator !== assertion.operator ||
              assertionFromDb.expectedValue !== assertion.expectedValue)
        )
      );
      if (updatedAssertions.length > 0) {
        let updatedAssertionsTemp = updatedAssertions.map((updatedAssertion) => {
          return assertionsList.find((assertion) => assertion.id === updatedAssertion.id);
        });
        updatedAssertionsTemp.map((updatedAssertion) => {
          dispatch(updateAssertion(suiteId, testId, stepId, updatedAssertion.id, updatedAssertion));
        });
      }
    }
    if (!secretsFound) {
      handleClose();
    }
  };

  const handleEditStep = (id) => {
    setEdit(true);
    const editableStep = stepsList.find((iStep) => {
      if (iStep.id) {
        setStepId(id);
        return iStep.id === id;
      } else {
        setLocalId(id);
        return iStep.localID === id;
      }
    });

    setStepName(editableStep.name);
    setStepDescription(editableStep.description);
    setStepMethod(editableStep.method);
    setStepURL(editableStep.url);
    setExecutionOrder(editableStep.executionOrder);
    setAssertionsList(editableStep.assertions === null ? [] : editableStep.assertions);
    let type = editableStep.headers['content-type'] || editableStep.headers.contentType || 'raw';
    setHeaders(editableStep.headers);
    setContentType(type);
    setBody(editableStep.body);
    setExtractVariablesList(editableStep.extractVariables);
    setPauseDuration(editableStep.pauseDuration);

    handleShowStepForms();
    if (testId !== undefined) {
      return;
    }
  };

  const handleDeleteStep = (id) => {
    if (testId !== undefined) {
      dispatch(deleteStep(suiteId, testId, id)).then(
        () => {
          setStepId(0);
          const newArray = stepsList.filter((iStep) => {
            if (iStep.id) {
              return iStep.id !== id;
            } else {
              return iStep.localID !== id;
            }
          });
          setStepsList(newArray);
          dispatch(commonActions.showSuccessAlert(`Step deleted successfully!`));
        },
        function () {
          dispatch(commonActions.showErrorAlert(`Failed to delete step`));
        }
      );
    }
  };

  const handleStepDrop = (droppedItem) => {
    if (testId == undefined) {
      var updatedList = [...stepsList];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      setStepsList(updatedList);
    } else {
      const stepRearrangeData = {
        stepId: droppedItem.draggableId,
        oldExecutionOrder: droppedItem.source.index + 1,
        newExecutionOrder: droppedItem.destination.index + 1
      };
      dispatch(rearrangeStep(suiteId, testId, droppedItem.draggableId, stepRearrangeData)).then(
        (response) => {
          setStepsList(response);
          dispatch(commonActions.showSuccessAlert(`Step rearranged successfully!`));
        },
        function () {
          dispatch(commonActions.showErrorAlert(`Failed to rearrange step`));
        }
      );
    }
  };

  const handleDeleteAssertion = (localAssertion) => {
    let newArray = [];
    if (testId !== undefined) {
      newArray = assertionsList.filter((assertion) =>
        assertion.localID
          ? assertion.localID != localAssertion.localID
          : assertion.id != localAssertion.id
      );
    } else {
      newArray = assertionsList.filter((assertion) => assertion.localID !== localAssertion.localID);
    }
    setAssertionsList(newArray);
  };

  const validateScriptSyntax = (script) => {
    setScripValidatorResponseMessage('');
    setErrorsList([]);
    try {
      Babel.parse(script, {
        plugins: ['jsx'],
        sourceType: 'module'
      });
    } catch (err) {
      setScripValidatorResponseMessage(err.message);
    }
    setScript(script);
    setScriptValidateFlag(false);
  };

  const handleValidate = () => {
    axiosInstance
      .post(
        `/v1/test_suites/${suiteId}/browser_tests/${
          testId === undefined ? 0 : testId
        }/script/validate`,
        {
          script: script
        }
      )
      .then((response) => {
        const errorPairs = [];
        let scriptData = response.data;
        if (scriptData.valid) {
          setScripValidatorResponseMessage('Valid Script');
          setScriptValidateFlag(true);
        } else {
          for (const key in scriptData) {
            if (scriptData[key] !== null && key !== 'valid') {
              errorPairs.push(`${key}: ${JSON.stringify(scriptData[key])}`);
            }
          }
          setScripValidatorResponseMessage('Invalid Script');
          setErrorsList(errorPairs);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <Grid hidden={step !== 3}>
      {type === TEST_TYPES.BROWSER ? (
        <>
          Note : Validate script before moving to the next step.
          <ConfirmDialog
            open={showErrorModal}
            minHeight="300px"
            maxHeight="400px"
            title="Script Validation Errors"
            disableSaveActions="true"
            cancelText="Close"
            handleClose={handleCloseErrorModal}>
            <Grid>
              {errorsList.map((error, index) => {
                const [key, errorText] = error.split(': [');
                const errors = errorText.substring(1, errorText.length - 2).split('","');
                return (
                  <Grid key={index}>
                    <p>
                      <strong>{key}:</strong>
                    </p>
                    {errors.map((line, lineIndex) => (
                      <p key={lineIndex}>{line.trim()}</p>
                    ))}
                  </Grid>
                );
              })}
            </Grid>
          </ConfirmDialog>
          <Box component="form" noValidate autoComplete="off" sx={{ my: '1.5rem' }}>
            <Tooltip title="Feature Coming Soon...">
              <span>
                <Button variant="outlined" disabled>
                  Start Recording
                </Button>
              </span>
            </Tooltip>
            <span style={{ float: 'right' }}>
              <Button variant="outlined" onClick={handleValidate}>
                Validate Script
              </Button>
            </span>
            <TextField
              sx={{ my: '1.5rem' }}
              id="test-script"
              label="Enter Script"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={9}
              value={script}
              onChange={(e) => validateScriptSyntax(e.target.value)}
              error={
                scripValidatorResponseMessage !== 'Valid Script' &&
                scripValidatorResponseMessage !== ''
              }
              helperText={
                <Grid
                  sx={{
                    color: scripValidatorResponseMessage === 'Valid Script' ? 'green' : 'red'
                  }}>
                  {scripValidatorResponseMessage}
                  {errorsList.length !== 0 && (
                    <Tooltip title="View More Details">
                      <IconButton key="view" onClick={() => setShowErrorModal(true)}>
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              }
            />
          </Box>
        </>
      ) : (
        <>
          <Grid hidden={showStepforms === true ?? false}>
            {!stepsList.length && 'Add your first step'}
            <Grid sx={{ my: '1rem' }}>
              <Button variant="outlined" onClick={handleResetAndShowStepForms}>
                Add Step
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
          <StepDetailsTabs
            stepName={stepName}
            setStepName={setStepName}
            stepDescription={stepDescription}
            setStepDescription={setStepDescription}
            stepMethod={stepMethod}
            setStepMethod={setStepMethod}
            stepURL={stepURL}
            setStepURL={setStepURL}
            assertionType={assertionType}
            setAssertionType={setAssertionType}
            assertionField={assertionField}
            setAssertionField={setAssertionField}
            assertionOperand={assertionOperand}
            setAssertionOperand={setAssertionOperand}
            assertionValue={assertionValue}
            setAssertionValue={setAssertionValue}
            showStepforms={showStepforms}
            handleCreateStepOBJ={handleCreateStepOBJ}
            handleCreateStepInUpdateFlow={handleCreateStepInUpdateFlow}
            handleUpdateStepInUpdateFlow={handleUpdateStepInUpdateFlow}
            handleClose={handleClose}
            operandsList={operandsList}
            assertionsList={assertionsList}
            handleAddAssertion={handleAddAssertion}
            handleDeleteAssertion={handleDeleteAssertion}
            openAssertionsModal={openAssertionsModal}
            setOpenAssertionsModal={setOpenAssertionsModal}
            edit={edit}
            setEdit={setEdit}
            headers={headers}
            setHeaders={setHeaders}
            body={body}
            setBody={setBody}
            contentType={contentType}
            setContentType={setContentType}
            pauseDuration={pauseDuration}
            setPauseDuration={setPauseDuration}
            extractVariablesList={extractVariablesList}
            setExtractVariablesList={setExtractVariablesList}
            override={override}
            setOverride={setOverride}
            secretsDetected={secretsDetected}
          />
          <Grid hidden={showStepforms}>
            <StepList
              stepsList={stepsList}
              handleEditStep={handleEditStep}
              handleDeleteStep={handleDeleteStep}
              handleStepDrop={handleStepDrop}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default StepDetailsForm;
