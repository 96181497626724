import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import { fetchAllTeamsForUser } from '../../store/slice/team/TeamActions.js';
import { useDispatch, useSelector } from 'react-redux';

const CreateTestSuiteModal = (props) => {
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.teamData?.teams);
  const products = props.products;
  const [suite, setSuite] = useState({});
  const [disableSave, setDisableSave] = useState(true);
  const [teamOptions, setTeamOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const handleSave = () => {
    props.handleSave(suite);
  };

  const handleClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (props.suite != undefined) {
      setSuite({
        name: props.suite.name,
        description: props.suite.description,
        teamName: props.suite.teamName,
        applicationName: props.suite.applicationName,
        productId: 'props.suite.productId'
      });
    }
  }, [props.suite]);

  useEffect(() => {
    const disable =
      !suite.name ||
      !suite.description ||
      !suite.teamName ||
      !suite.applicationName ||
      !suite.productId;
    setDisableSave(disable);
  }, [suite]);

  useEffect(() => {
    if (teams != undefined && teams?.teamSummaryDTOS?.length > 0) {
      let options = teams?.teamSummaryDTOS?.map((x) => {
        return {
          label: x.teamName
        };
      });
      setTeamOptions(options);
    }
  }, [teams]);

  useEffect(() => {
    if (products != undefined && products.length > 0) {
      const options = products.map((x) => {
        return {
          id: x.productId,
          label: x.name
        };
      });
      setProductOptions(options);
    }
  }, [products]);

  useEffect(() => {
    dispatch(fetchAllTeamsForUser(0, 100));
  }, []);

  const fetchProductLabel = (productId) => {
    let productLabel = productId;
    if (products != undefined && products.length > 0) {
      let product = products.find((product) => product.productId === productId);
      productLabel = product ? product.name : productId;
    }
    return productLabel;
  };

  return (
    <ConfirmDialog
      open={props.open}
      width={props.width}
      title={props.title}
      saveText={props.title == 'Create New Test Suite' ? 'Create Test Suite' : null}
      handleSave={handleSave}
      handleClose={handleClose}
      disableSave={disableSave}>
      <TextField
        margin="dense"
        data-testid="create-suite-name"
        label="Suite Name"
        required
        type="text"
        fullWidth
        variant="outlined"
        disabled={props.suite?.name ? true : false}
        defaultValue={props.suite ? props.suite.name : ''}
        onChange={(e) => setSuite({ ...suite, name: e.target.value })}
        sx={{ mb: 1 }}
      />
      <TextField
        margin="dense"
        data-testid="create-suite-desc"
        multiline
        required
        rows={2}
        label="Description"
        fullWidth
        variant="outlined"
        defaultValue={props.suite ? props.suite.description : ''}
        onChange={(e) => setSuite({ ...suite, description: e.target.value })}
        sx={{ my: 1 }}
      />
      <TextField
        margin="dense"
        data-testid="create-suite-app-name"
        label="Application Name"
        required
        type="text"
        fullWidth
        variant="outlined"
        defaultValue={props.suite ? props.suite.applicationName : ''}
        onChange={(e) => setSuite({ ...suite, applicationName: e.target.value })}
        sx={{ my: 1 }}
      />
      <Autocomplete
        data-testid="create-suite-productid-combobox"
        options={productOptions}
        required
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Select Product" />}
        defaultValue={props.suite ? fetchProductLabel(props.suite.productId) : ''}
        onChange={(event, newValue) => {
          setSuite({
            ...suite,
            productId: newValue && newValue.id
          });
        }}
        sx={{ my: 1 }}
      />
      <Autocomplete
        data-testid="create-team-combo-box-suite"
        options={teamOptions}
        required
        disabled={teams?.length == 0}
        defaultValue={props.suite ? props.suite.teamName : ''}
        isOptionEqualToValue={(option, value) => option === value}
        renderInput={(params) => <TextField {...params} label="Select Team" />}
        onChange={(event, newValue) => {
          setSuite({
            ...suite,
            teamName: newValue ? newValue.label : ''
          });
        }}
        sx={{ mt: 2 }}
      />
    </ConfirmDialog>
  );
};
export default CreateTestSuiteModal;
