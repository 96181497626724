import axiosInstance from '../../../services/axios';
import { commonActions } from '../CommonSlice';
import { teamActions } from './TeamSlice';

export const fetchAllTeamsForUser = (pageStart, pageSize) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/teams?page=${pageStart}&countPerPage=${pageSize}&expand=true`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const teams = await fetchData();
      dispatch(teamActions.updateTeamList(teams));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchAllTeams = (pageStart) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/ui/teams?page=${pageStart}&countPerPage=12&expand=true`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const allTeams = await fetchData();
      dispatch(teamActions.updateAllTeamList(allTeams));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchTeamById = (id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/teams/${id}?expand=true`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const team = await fetchData();
      dispatch(teamActions.updateTeam(team));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const createTeam = (teamData) => {
  return async (dispatch) => {
    const addData = async () => {
      await axiosInstance({
        method: 'post',
        url: '/v1/teams',
        data: teamData
      });
    };
    try {
      return addData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const updateTeam = (id, teamData) => {
  return (dispatch) => {
    dispatch(commonActions.showLoadingIcon(true));
    const updateData = async () => {
      await axiosInstance({
        method: 'put',
        url: `/v1/teams/${id}`,
        data: teamData
      });
    };
    try {
      return updateData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteTeam = (id) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/teams/${id}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const addTeamMember = (teamId, teamMemberData) => {
  return (dispatch) => {
    const addData = async () => {
      await axiosInstance({
        method: 'post',
        url: `/v1/teams/${teamId}/team_members`,
        data: teamMemberData
      });
    };
    try {
      return addData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteTeamMember = (teamId, teamMemberId) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/teams/${teamId}/team_members/${teamMemberId}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};
