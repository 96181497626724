import { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import EditIcon from '../../assets/EditIcon.svg';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import ExportIcon from '../../assets/ExportIcon.svg';
import { Box, Chip, Grid, Tabs, Typography, Tab } from '@mui/material';
import ConfirmDialog from '../../components/modal/ConfirmDialog';
import CreateEnvironmentModal from './CreateEnvironmentModal';
import EnvironmentExportForm from './EnvironmentExportForm';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createVariable,
  deleteVariable,
  updateVariable
} from '../../store/slice/variable/VariableActions';
import {
  deleteEnvironment,
  fetchEnvironmentById,
  updateEnvironment
} from '../../store/slice/environment/EnvironmentActions';
import { useParams } from 'react-router-dom';
import { commonActions } from '../../store/slice/CommonSlice';
import { environmentActions } from '../../store/slice/environment/EnvironmentSlice';
import AppBreadCrumb from '../../components/breadCrumbs/AppBreadCrumb';
import {
  HomeBreadCrumb,
  TestSuiteBreadCrumb,
  EnvironmentDetailsBreadCrumb
} from '../../utils/BreadCrumbUtils';
import CreateVariableModal from '../variable/CreateVariableModal';
import VariablesTable from '../variable/VariablesTable';
import PropTypes from 'prop-types';
import AlertConditionsForm from '../createTest/createTestForms/AlertConditionsForm';
import { WEBHOOK_REGEX } from '../../utils/WebhookRegex';

const EnvironmentDetails = () => {
  const { suiteId, id } = useParams();
  const TestSuiteDetailsBreadCrumb = {
    link: `${window.location.pathname.split('/environments', 1)}`,
    title: 'Test Suite Details'
  };
  const breadcrumbAr = [
    HomeBreadCrumb,
    TestSuiteBreadCrumb,
    TestSuiteDetailsBreadCrumb,
    EnvironmentDetailsBreadCrumb
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const environment = useSelector((state) => state.environmentData.environment);
  const [value, setValue] = useState(0);
  const [openEditEnvModal, setOpenEditEnvModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteVarModal, setOpenDeleteVarModal] = useState(false);
  const [openAddVariableModal, setOpenAddVariableModal] = useState(false);
  const [openEditVariableModal, setOpenEditVariableModal] = useState(false);
  const [openExportEnvironmentModal, setOpenExportEnvironmentModal] = useState(false);
  const [variable, setVariable] = useState({});
  const onEditEnvironmentBtnClose = () => setOpenEditEnvModal(false);
  const onDeleteEnvironmentBtnClose = () => setOpenDeleteModal(false);
  const [alertCondition, setAlertCondition] = useState(environment?.alertCondition || null);
  const [alertWebhook, setAlertWebhook] = useState(environment?.alertWebhook || '');
  const [webhookError, setWebhookError] = useState('');
  const [customLabels, setCustomLabels] = useState({});
  const onEditEnvironmentBtnClick = () => {
    setOpenEditEnvModal(true);
  };
  const onExportEnvironmentBtnClick = () => {
    setOpenExportEnvironmentModal(true);
  };
  const onDeleteEnvironmentBtnClick = () => {
    setOpenDeleteModal(true);
  };

  const TabPanel = useMemo(() => {
    const MemoizedTabPanel = (props) => {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}>
          {value === index && (
            <Box sx={{ px: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    };
    MemoizedTabPanel.displayName = 'TabPanel';

    return MemoizedTabPanel;
  }, []);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function getTabDetails(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const onDeleteVariableBtnClick = (_e, variable) => {
    setVariable(variable);
    setOpenDeleteVarModal(true);
  };
  const onDeleteVariableBtnClose = () => {
    setVariable({});
    setOpenDeleteVarModal(false);
  };

  useEffect(() => {
    dispatch(fetchEnvironmentById(suiteId, id));
  }, []);

  useEffect(() => {
    dispatch(handleReset);
  }, [environment]);

  const onSaveEnvironment = (environmentData) => {
    environmentData.id = id;
    environmentData.testSuiteId = suiteId;
    environmentData.alertWebhook = alertWebhook;
    environmentData.alertCondition = alertCondition;
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(updateEnvironment(suiteId, id, environmentData)).then(
      () => {
        setOpenEditEnvModal(false);
        dispatch(commonActions.showSuccessAlert(`Environment ${environmentData.name} updated`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(fetchEnvironmentById(suiteId, id));
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  const onDeleteEnvironment = () => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(deleteEnvironment(suiteId, environment.id)).then(
      () => {
        setOpenDeleteModal(false);
        dispatch(commonActions.showSuccessAlert(`Environment ${environment.name} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(environmentActions.resetEnvironment());
        navigate(TestSuiteDetailsBreadCrumb.link);
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  const onAddVariableBtnClick = () => {
    setOpenAddVariableModal(true);
  };

  const onAddVariableBtnClose = () => {
    setOpenAddVariableModal(false);
  };

  const onEditVariableBtnClick = (_e, variableData) => {
    setVariable(variableData);
    setOpenEditVariableModal(true);
  };

  const onEditVariableBtnClose = () => {
    setVariable({});
    setOpenEditVariableModal(false);
  };

  const onSaveVariable = (variableData) => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(createVariable(suiteId, id, variableData)).then(
      () => {
        setOpenAddVariableModal(false);
        dispatch(commonActions.showSuccessAlert(`Variable ${variableData.name} added`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(fetchEnvironmentById(suiteId, id));
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  const onUpdateVariable = (variableData) => {
    variableData.environmentId = id;
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(updateVariable(suiteId, id, variableData.id, variableData)).then(
      () => {
        setOpenEditVariableModal(false);
        dispatch(commonActions.showSuccessAlert(`Variable ${variableData.name} updated`));
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(fetchEnvironmentById(suiteId, id));
      },
      function (error) {
        dispatch(commonActions.showLoadingIcon(false));
        dispatch(commonActions.showErrorAlert(error.response.data.message));
      }
    );
  };

  const onDeleteVariable = () => {
    dispatch(commonActions.showLoadingIcon(true));
    dispatch(deleteVariable(suiteId, id, variable.id)).then(
      () => {
        setOpenDeleteVarModal(false);
        dispatch(commonActions.showSuccessAlert(`Variable  ${variable.name} deleted`));
        dispatch(commonActions.showLoadingIcon(false));
        setVariable({});
        dispatch(fetchEnvironmentById(suiteId, id));
      },
      function () {
        dispatch(commonActions.showLoadingIcon(false));
      }
    );
  };

  const handleAlertCondition = (value, key) => {
    if (key === 'type' && value === '') {
      setCustomLabels({});
      setAlertCondition(null);
      setAlertWebhook('');
    } else {
      setAlertCondition((alertCondition) => {
        return {
          ...alertCondition,
          type: key === 'type' ? value : alertCondition?.type,
          threshold: key === 'threshold' ? value : alertCondition?.threshold
        };
      });
      if (key === 'webhook') {
        setWebhookError(!value.length || WEBHOOK_REGEX.test(value) ? '' : 'Incorrect webhook url');
        setAlertWebhook(value);
      }
    }
  };

  const saveAlertCondition = () => {
    let environmentData = { ...environment };
    environmentData.alertCondition = alertCondition;
    environmentData.alertWebhook = alertWebhook;
    onSaveEnvironment(environmentData);
  };

  const handleReset = () => {
    setAlertCondition(environment?.alertCondition);
    setAlertWebhook(environment?.alertWebhook);
    setCustomLabels(environment?.alertCondition?.customLabels);
  };

  return (
    <div style={{ maxWidth: '90%', position: 'relative', minHeight: '75vh' }}>
      <AppBreadCrumb data={breadcrumbAr} />
      <Grid container sx={{ marginLeft: '130px' }}>
        <Grid item xs={12} sx={{ marginTop: '50px' }}>
          <div style={{ marginLeft: '60px ' }} className="label">
            ENVIRONMENT DETAILS
          </div>
        </Grid>
        {environment.name.length == 0 && (
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ paddingLeft: '130px', paddingTop: '20px' }}>
              Loading...
            </Typography>
          </Grid>
        )}
        {environment.name.length > 0 && (
          <Grid container>
            <Grid item xs={12}>
              <Grid
                style={{ marginLeft: '60px ' }}
                className="heading_team"
                data-testid="environment-name">
                {environment.name}
              </Grid>
              <Grid
                style={{ marginLeft: '60px ' }}
                className="description"
                data-testid="environment-desc">
                {environment.description}
              </Grid>
              <Grid ml="60px" mt="1rem" data-testid="location-names">
                Locations
              </Grid>
              <Grid sx={{ ml: '60px', mt: '10px' }}>
                {environment.locations.map((location, index) => (
                  <Chip sx={{ mr: 1 }} size="small" key={index} label={location} />
                ))}
              </Grid>
            </Grid>
            <Grid container className="description" ml="40px" my="1rem">
              <Button
                variant="text"
                data-testid="edit-environment-button"
                onClick={onEditEnvironmentBtnClick}>
                <img src={EditIcon} />
                Edit
              </Button>
              <Button
                variant="text"
                data-testid="delete-environment-button"
                onClick={onDeleteEnvironmentBtnClick}>
                <img src={DeleteIcon} />
                Delete
              </Button>
              <Button
                variant="text"
                data-testid="export-environment-button"
                onClick={onExportEnvironmentBtnClick}>
                <img src={ExportIcon} />
                Export
              </Button>
            </Grid>
            <Box sx={{ width: '100%', position: 'relative' }}>
              <Box sx={{ ml: '3%' }}>
                <Tabs value={value} onChange={handleTabChange} aria-label="tab-panels">
                  <Tab label="Variables" {...getTabDetails(0)} />
                  <Tab
                    label="Alert Conditions"
                    data-testid="alert-conditions-tab"
                    {...getTabDetails(0)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid
                  container
                  width="90%"
                  className="table-container-style"
                  data-testid="variables-table">
                  <Grid
                    container
                    py={4}
                    ml={3}
                    sx={{ background: 'rgba(191, 215, 221, 0.2)', borderRadius: '8px' }}>
                    <VariablesTable
                      environment={environment}
                      onDeleteVariableBtnClick={onDeleteVariableBtnClick}
                      onAddVariableBtnClick={onAddVariableBtnClick}
                      onEditVariableBtnClick={onEditVariableBtnClick}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid
                  container
                  width="90%"
                  className="table-container-style"
                  data-testid="variables-table">
                  <Grid
                    container
                    sx={{
                      background: 'rgba(191, 215, 221, 0.2)',
                      borderRadius: '8px',
                      ml: 3,
                      px: 3
                    }}>
                    <AlertConditionsForm
                      step={5}
                      handleAlertCondition={handleAlertCondition}
                      webhookError={webhookError}
                      alertCondition={alertCondition}
                      setAlertCondition={setAlertCondition}
                      alertWebhook={alertWebhook}
                      customLabels={customLabels}
                      setCustomLabels={setCustomLabels}
                      environment={true}
                    />
                  </Grid>
                  <Grid container sx={{ justifyContent: 'end', my: 2 }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'transparent',
                        color: '#00243D',
                        '&:hover': {
                          backgroundColor: '#00243D',
                          color: '#fff'
                        }
                      }}
                      onClick={handleReset}>
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        '&:hover': {
                          backgroundColor: '#00243D'
                        }
                      }}
                      disabled={
                        !(
                          !alertCondition ||
                          (alertCondition &&
                            alertCondition.threshold &&
                            alertCondition.type &&
                            alertWebhook &&
                            !webhookError) ||
                          (alertCondition &&
                            !alertCondition.threshold &&
                            !alertCondition.type &&
                            !alertWebhook)
                        )
                      }
                      data-testid="save-alert-conditions-button"
                      onClick={saveAlertCondition}>
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Grid>
        )}
      </Grid>
      <EnvironmentExportForm
        open={openExportEnvironmentModal}
        width="xs"
        title="Export Environment"
        jsonData={JSON.stringify(environment, null, 2)}
        setOpenExportEnvironmentModal={setOpenExportEnvironmentModal}
        environment={environment}
      />
      <CreateEnvironmentModal
        open={openEditEnvModal}
        width="xs"
        title="Edit Environment"
        handleSave={onSaveEnvironment}
        handleClose={onEditEnvironmentBtnClose}
        environment={environment}
      />
      <CreateVariableModal
        open={openAddVariableModal}
        width="xs"
        title="Create New Variable"
        handleSave={onSaveVariable}
        handleClose={onAddVariableBtnClose}
        environment={environment}
        variable={{}}
      />
      <CreateVariableModal
        open={openEditVariableModal}
        width="xs"
        title="Edit Variable"
        handleSave={onUpdateVariable}
        handleClose={onEditVariableBtnClose}
        environment={environment}
        variable={variable}
      />
      <ConfirmDialog
        open={openDeleteModal}
        width="xs"
        title="Delete Environment"
        handleSave={onDeleteEnvironment}
        saveText="Delete"
        handleClose={onDeleteEnvironmentBtnClose}>
        Are you sure you want to delete this environment? This cannot be undone after it is deleted.
      </ConfirmDialog>
      <ConfirmDialog
        open={openDeleteVarModal}
        width="xs"
        title="Delete Variable"
        handleSave={onDeleteVariable}
        saveText="Delete"
        handleClose={onDeleteVariableBtnClose}>
        Are you sure you want to delete this variable?
      </ConfirmDialog>
    </div>
  );
};

export default EnvironmentDetails;
